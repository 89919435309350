<template>
<div>
  <box title="Selecione tipo de carga">
      <b-form-select v-model="uploadType" :options="uploadOpts"/>
  </box>
    <box title="Lote de Prórrogas" v-if="uploadType == 1">
      <b-form @submit.prevent="onSubmit">
        <label>Selecione archivo a cargar: </label>
        <b-form-file @change.prevent="" v-model="file" :state="Boolean(file)" placeholder="Choose a file or drop it here..." accept="text/csv, .csv" drop-placeholder="Drop file here..." required></b-form-file>
        <!-- <div class="m-3">Selected file: {{ file ? file.name : '' }}</div> -->
          <label id="lote">Lote #:</label>
        <b-form-input type="number" id="lote" v-model="lote" required>Lote</b-form-input>
        <b-button class="mt-3" type="submit" variant="primary">Upload</b-button>
        <b-form-checkbox id="sendEmailsP" v-model="sendEmailsP" name="sendEmailsP" :value="true" :unchecked-value="false">Send Prórroga Emails</b-form-checkbox>
      </b-form>
      <h1>CSV File Headers</h1>
      <div class="ml-3">
        <b-row>serial,f_solicitud,cedula,fullName</b-row>
        <b-row>A02625304,7/10/2020,26601489,Camilo Jose Lopez Marcano</b-row>
        <b-col >
        <b-table stacked bordered table-variant="warning" class="mt-3" :items="items" :fields="fields" ></b-table>
      </b-col>
      </div>
    </box>

    <box title="Citas Pasaportes" v-if="uploadType == 2">
      <!-- <b-form @submit.prevent="onSubmitCP"> -->
      <div class="overflow-auto">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
        Numero de registros: {{rows}}
       
        <b-table id="my-table" hover striped header-variant="dark" :items="tabla_citas" :fields="citas_fields" primary-key="serial" sort-by="name"
        :sortDesc="true" :current-page="currentPage" :per-page="perPage" small responsive selectable ref="selectableTable" select-mode="multi" @row-selected="onRowSelected">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </div>
      <b-button variant="primary" size="small" @click="onSubmitCP">Cargar Citas</b-button>
        <!-- <label>Selecione archivo a cargar: </label> -->
        <!-- <b-form-file @change.prevent="" v-model="filecp" :state="Boolean(file)" placeholder="Choose a file or drop it here..." accept="text/txt, .txt" drop-placeholder="Drop file here..." required></b-form-file> -->
        <!-- <div class="m-3">Selected file: {{ file ? file.name : '' }}</div> -->
        <!-- <b-button class="mt-3" type="submit" variant="primary">Upload</b-button> -->
        <!-- <b-form-checkbox id="sendEmailsCP" v-model="sendEmailsCP" name="sendEmailsCP" :value="true" :unchecked-value="false">Send Appointments Email</b-form-checkbox> -->
      <!-- </b-form> -->
    </box>

    <box title="Sincronizar Registro Consular">
      <b-button pill variant="primary" class="mb-2" @click="onSyncRC">Sincronizar</b-button>
      <div>
        <h2>Prórrogas</h2>
        Sin Registro: {{cprorrogas.length}}
        <b-table hover striped header-variant="dark" :items="cprorrogas" :fields="profields" small responsive>
              <template #cell(email)="data">
                <b-link :href="'mailto:'+data.value">{{data.value}}</b-link>
              </template>
              <template #cell(tracking)="data">
                <b-link target="_blank" :href="'https://auspost.com.au/mypost/track/#/details/'+data.value">
                <b-img src="../assets/Australia_Post_logo.png" width="35px"></b-img></b-link>
              </template>
              <template #cell(phone)="data">
                {{data.value}}
              </template>
              <template #cell(update)="data">
                <b-button @click="showEdit(data.item)" size="sm" variant="primary">Update</b-button>
              </template>
            </b-table>
      </div>

      <!-- <div>
        <h2>Citas de Pasaporte</h2>
        Sin Registro: {{cpasaportes.length}}
        <b-table hover striped header-variant="dark" :items="cpasaportes" :fields="pasfields" small responsive>
          <template #cell(update)="data">
           <b-button @click="showModal('list'+data.item.serial)" size="sm" variant="primary">Update</b-button>
          </template>

          <template #cell(SendMail)="data">
            <b-button @click="showModal('list'+data.item.fullName)" size="sm" variant="primary">Emails</b-button>
          </template>
          <template #cell(IM)="data">
            <b-link :href="'https://wa.me/'+data.item.phone+'/?text='+IM" target="_blank"><b-img src="../assets/whatsapp-icon.png" width="35px"></b-img></b-link>
          </template>
        </b-table>
      </div> -->
    </box>
</div>
</template>
<script>
import box from '../components/parts/box.vue';

export default {
  name: 'Upload',
  components: {
    box
  },
  data(){
    return {
      sendEmailsCP: false,
      sendEmailsP: false,
      file: undefined,
      filerc: null,
      filecp: null,
      lote: null,
      uploadType: 0,
      cpasaportes: [],
      cprorrogas: [],
      perPage: 10,
      currentPage: 1,
      selectedItems: [],
      tabla_citas: [],
      citas_fields: [
        {key: 'name', label: 'Nombre de Archivo'},{key: 'selected', label: 'Seleccionado'}
      ],
      items: [
        { serial: 'Serial de la prórrga. Ej. A02625304',
         f_solicitud: 'Fecha de solicitud. Ej. 7/10/2020' ,
         cedula: 'Número de cédula sin el serial (V/E). Ej. 26601489. NOTA: Menores deben usar el campo en blanco o el número 0.' ,
         fullName: 'Nombre Completo. Ej. Camilo Jose Lopez Marcano'}
      ],
      itemsRC: [
        { cedula_numero: 'Número de cédula sin el serial (V/E). Menores puede estar en blanco.',
        nombres: 'Nombres de la persona.' ,
        apellidos: 'Apellidos de la persona.' ,
        email: 'Correo electrónico.',
        telefono: 'Número de telefono.'}
      ],
      fields: [
        {key: 'serial', label: 'serial'}, { key: 'f_solicitud', label: 'f_solicitud'}, { key: 'cedula', label: 'cedula'}, { key: 'fullName', label: 'fullName'}
      ],
      fieldsRC: [
        {key: 'cedula_numero', label: 'cedula_numero'}, { key: 'nombres', label: 'nombres'}, { key: 'apellidos', label: 'apellidos'}, { key: 'email', label: 'email'}, { key: 'telefono', label: 'telefono'}
      ],
      fieldsCP: [ {key: 'serial', label: 'serial'}, {key: 'saime_date', label: 'saime_date'},{key: 'cedula', label: 'cedula'},{ key: 'fullName', label: 'fullName'},{key: 'emb_date', label: 'emb_date'}, {key: 'hora', label: 'hora'}],
      uploadOpts: [ {value: 0, text: 'Select Upload Type', disable: true},{value: 1, text: 'Prórrogas'}],
      pasfields: [{ key: 'serial', label: 'Serial'},
              { key: 'saime_date', label: 'Cita SAIME', formatter: value => { return this.dateFormat(value)}},
              { key: 'emb_date', label: 'Cita Embajada', formatter: value => { return this.dateFormat(value)}},
                // const datetime = this.dateFormat(value); const date = datetime[0]; return date}},
              { key: 'cedula', label: 'Cédula' },{ key: 'fullName', label: 'Nombre Completo'}],
      profields: [{ key: 'lote', label: 'Lote', sortable: true},'serial', { key: 'f_solicitud', label: 'Fecha de Solicitud', formatter: value => {return this.dateFormat(value)}},
              { key: 'cedula', label: 'Cédula' }, { key: 'fullName', label: 'Nombre Completo'}, { key: 'f_estampado', label: 'Fecha de Estampado', formatter: value => {return this.dateFormat(value)}}]
    }
  },
  computed: {
    rows(){
      console.log(this.tabla_citas.length)
      return this.tabla_citas.length
    }
  },
  methods: {
    dateFormat(date){
      const value = new Date(date);
      // console.log('DATE:',value)
      // console.log('TO LOCALE:',value.toLocaleDateString('en-AU', {year: "numeric", day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit"}))
      // const timestamp = 
      return value.toLocaleDateString('en-AU', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    },
    async onSubmit(){
      // event.preventDefault();
      let formData = new FormData();
      
      formData.append('csv', JSON.stringify({ id: this.$store.state.id, sendEmails: this.sendEmailsP }));
      formData.append('csv', this.file);

      const upload = await fetch(`/api/save/${this.lote}`,{ method: 'PUT', mode: 'cors', body: formData }).then(res => res.json());
      
      alert(JSON.stringify(upload));
    },
    onSubmitCP(){
      const body = JSON.stringify({id: this.$store.state.id,files: this.selectedItems});
      const upload = fetch('/api/uploaddatapassport/',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body }).then(res => res.json());
      this.clearSelected();
      alert(JSON.stringify(upload.message));
      // alert('Cargando citas. Dirijase al dia de las citas!')
    },
    async getNotFounds(){
      const data = await fetch('/api/getNotFounds/').then(res => res.json());
      this.cpasaportes = data[1];
      this.cprorrogas = data[0];
    },
    async onSyncRC(){
      const body = JSON.stringify({id: this.$store.state.id})
      await fetch('/api/syncrc',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body });
      this.getNotFounds();
    },
    // async get_citas(){
    //   const data_citas = await fetch('/api/getcitas').then(res => res.json());
    //   this.tabla_citas = data_citas;
    // },
    onRowSelected(items) {
        this.selectedItems = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    }
  },
  async mounted(){
    this.getNotFounds();
    // this.get_citas();
  }
}
</script>